import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";
import TronWeb from 'tronweb';

import logoImg from "../assets/img/logo-light.png";
import printerImg from "../assets/icons/3-2-printer-png-picture.png";
import printerUpImg from "../assets/icons/printer-up.png";
import clickImg from "../assets/icons/click-svgrepo-com.svg";
import upImg from "../assets/icons/upgrade-svgrepo-com.svg";
import iconSave from "../assets/icons/save-svgrepo-com.svg";

const ClickerGame = () => {
  const [data, setData] = useState("");
  const [loadedData, setLoadedData] = useState(null);
  const [saved, setSaved] = useState(false);
  const [metamaskAddress, setMetamaskAddress] = useState(null);
  const api = "https://play.printbnb.cash/api";
  const [phantomWalletId, setPhantomWalletId] = useState(null);
  const [tronWalletAddress, setTronWalletAddress] = useState(null);
  const [tronWebInstance, setTronWebInstance] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [score, setScore] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);
  const [clickValue, setClickValue] = useState(0.1);
  const [clickUpgrades, setClickUpgrades] = useState(1);
  const [machines, setMachines] = useState(0);
  const [machinesUpgrades, setMachinesUpgrades] = useState(1);
  const [pointsPerSecond, setPointsPerSecond] = useState(0);
  const [totalPointsPerSecond, setTotalPointsPerSecond] = useState(0);
  const [timer, setTimer] = useState(0);
  const [saveCicle, setSaveCicle] = useState(0);
  const [oldScore, setOldScore] = useState(0);
  const [coldownTimeout, setColdownTimeout] = useState(false);
  const GlobalClickCost = 1 * Math.pow(1.5, clickUpgrades - 1);
  const GlobalMachineUpCost = 10 * Math.pow(1.5, (machinesUpgrades - 1) * 10);
  const GlobalMachineCost = 10 * Math.pow(1.5, machines);

  useEffect(() => {
    if (coldownTimeout) {
      const timeoutId = setTimeout(() => {
        setColdownTimeout(false);
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [coldownTimeout]);

  useEffect(() => {
    if (score > 0) {
      setStart(true);
    }
    const interval = setInterval(() => {
      if (start) {
        setScore(score + pointsPerSecond / 10);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [pointsPerSecond, score]);

  // useEffect(() => {
  //   if (!loadedData) return;
  //   setScore(loadedData.score || 0);
  //   setMachines(loadedData.machines.qtd || 0);
  //   setPointsPerSecond(
  //     ((loadedData.machines.qtd || 0) / 10) *
  //       (loadedData.machines.upgradesMultiplier || 1)
  //   );
  //   setMachinesUpgrades(loadedData.machines.upgradesMultiplier || 1);

  //   setClickUpgrades(loadedData.click.upgradesMultiplier || 0);
  //   setClickValue(0.1 * (loadedData.click.upgradesMultiplier || 0));
  //   setTotalClicks(loadedData.click.Clicks || 0);
  //   setTimer(loadedData.gameTime || 0);
  // }, [loadedData]);

  useEffect(() => {
    if (currentAddress) fetchData();
  }, [currentAddress]);

  async function loadUserAddress() {
    if (window.ethereum) {
      try {
        // Requests permission to access the MetaMask wallet
        await window.ethereum.request({ method: "eth_requestAccounts" });
        // Gets the connected user's address
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        const address = accounts[0];
        setMetamaskAddress(address);
        if (!phantomWalletId) setCurrentAddress(address);
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      console.error("MetaMask not detected");
    }
  }

  const connectToWallet = async () => {
    try {
      if (window.solana && window.solana.isPhantom) {
        // Connect to the Phantom Wallet
        window.solana
          .connect()
          .then(() => {
            // Update the state with the ID of the connected Phantom Wallet
            const address = window.solana.publicKey.toString();
            setPhantomWalletId(address);
            if (!metamaskAddress) setCurrentAddress(address);
          })
          .catch((error) => {
            console.error("Error connecting to Phantom Wallet:", error);
          });
      } else {
        console.error("Phantom Wallet is not installed");
      }
    } catch (error) {
      console.error("Error connecting to Phantom Wallet:", error);
    }
  };
  
  const connectTronWallet = async () => {
    setCurrentAddress("TELQGsoFgmgUTRPg2u8qJMYdAkE24HVXpU")
    setTronWalletAddress("TELQGsoFgmgUTRPg2u8qJMYdAkE24HVXpU")
    // try {
    //   const tronWeb = new TronWeb({
    //     fullHost: 'https://api.trongrid.io',
    //   });
    //   await tronWeb.setTronWeb(window.tronWeb);
    //   // TronLink is connected successfully
    //   console.log('Connected to TronLink!');
    // } catch (error) {
    //   // TronLink connection failed
    //   console.error('Failed to connect to TronLink:', error);
    // }
  };

  const [start, setStart] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  useEffect(() => {
    const intervalCount = setInterval(() => {
      if (start) {
        setTotalPointsPerSecond(score - oldScore);
        setOldScore(score);
        if (timer === 3600) {
          setStart(false);
          setIsOpenModalSubmit(true);
          setFinalScore(score);
        } else {
          setTimer((timer) => timer + 1);
        }
      }
    }, 1000);
    return () => clearInterval(intervalCount);
  }, [timer, start]);

  const handleButtonClick = () => {
    setScore(score + clickValue);
    setTotalClicks(totalClicks + 1);
  };

  const handleUpgradeClick = () => {
    const clickUpgradesCost = GlobalClickCost;
    if (score >= clickUpgradesCost) {
      setClickUpgrades((clickUpgrades) => clickUpgrades + 1);
      setScore(score - clickUpgradesCost);
      setClickValue(0.1 * (clickUpgrades + 1));
    } else {
      alert("Not enough points to buy");
    }
  };
  const handleBuyMachine = () => {
    const machineCost = GlobalMachineCost;
    if (score >= machineCost) {
      setMachines((machines) => machines + 1);
      setScore(score - machineCost);
      setPointsPerSecond(((machines + 1) / 10) * machinesUpgrades);
    } else {
      alert("Not enough points to buy");
    }
  };
  const handleUpgradeMachine = () => {
    const UpgradeMachineCost = GlobalMachineUpCost;
    if (score >= UpgradeMachineCost) {
      setMachinesUpgrades((machinesUpgrades) => machinesUpgrades + 0.1);
      setScore(score - UpgradeMachineCost);
      setPointsPerSecond((machines / 10) * (machinesUpgrades + 0.1));
    } else {
      alert("Not enough points to buy");
    }
  };
  const formatTime = (totalSeconds) => {
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const remainingSeconds = totalSeconds % 60;

    return `${hours} hour, ${minutes} minutes, ${remainingSeconds} seconds`;
  };

  const fetchData = async () => {
    if (!currentAddress) return;
    try {
      const response = await axios.get(`${api}/load/${currentAddress}`);
      setLoadedData(response.data.data);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const handleSave = async () => {
    if (!currentAddress) return;
    try {
      const data = {
        score: finalScore,
        click: { Clicks: totalClicks, upgradesMultiplier: clickUpgrades },
        machines: { qtd: machines, upgradesMultiplier: machinesUpgrades },
        gameTime: timer,
      };
      await axios.post(`${api}/save`, {
        user_id: currentAddress,
        data: data,
      });
      setSaved(true);
      handleRestart();
    } catch (error) {
      alert("Error saving data");
      setSaved(false);
    }
  };

  const [isPressed, setIsPressed] = useState(false);
  const [isPressed2, setIsPressed2] = useState(false);
  const [isPressed3, setIsPressed3] = useState(false);
  const [isPressed4, setIsPressed4] = useState(false);
  const handlePress = () => {
    setIsPressed(true);
    setTimeout(() => setIsPressed(false), 100); // Define um atraso para reverter o estado após 200ms
  };
  const handlePress2 = () => {
    setIsPressed2(true);
    setTimeout(() => setIsPressed2(false), 100); // Define um atraso para reverter o estado após 200ms
  };
  const handlePress3 = () => {
    setIsPressed3(true);
    setTimeout(() => setIsPressed3(false), 100); // Define um atraso para reverter o estado após 200ms
  };
  const handlePress4 = () => {
    setIsPressed4(true);
    setTimeout(() => setIsPressed4(false), 100); // Define um atraso para reverter o estado após 200ms
  };

  function formatString(str) {
    if (str.length <= 7) {
      return str; // Retorna a string original se tiver 7 caracteres ou menos
    } else {
      const firstThree = str.slice(0, 3); // Obtém os primeiros três caracteres
      const lastFour = str.slice(-4); // Obtém os últimos quatro caracteres
      return `${firstThree}...${lastFour}`; // Retorna os três primeiros, "..." e os quatro últimos
    }
  }

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  //modal submit
  const [isOpenModalSubmit, setIsOpenModalSubmit] = useState(false);

  const handleRestart = () => {
    window.location.reload();
  };
  function formatNumber(number) {
    return Number(number).toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: 3,
    });
  }
  
  
  return (
    <div className="click-game">
      {isOpenModalSubmit && (
        <div className="submit-modal">
          <div className="submit-modal-content">
            <small>Your best score:</small>
            <span
              style={{
                color: (loadedData?.score || 0) <= finalScore ? "red" : "green",
              }}
            >
              $ {formatNumber((loadedData?.score || 0).toFixed(2))}
            </span>
            <small>Your score:</small>
            <span
              style={{
                color: (loadedData?.score || 0) > finalScore ? "red" : "green",
              }}
            >
              $ {formatNumber(finalScore.toFixed(2))}
            </span>
            <small>Connect wallet:</small>
            {/* <div className="wallet-btn">
              {metamaskAddress ? (
                <p
                  className={
                    currentAddress === metamaskAddress
                      ? "ative-address address-selector"
                      : "address-selector"
                  }
                  onClick={() => {
                    if (!coldownTimeout && currentAddress !== metamaskAddress) {
                      setCurrentAddress(metamaskAddress);
                      setColdownTimeout(true);
                    }
                  }}
                >
                  User ID (Metamask): {formatString(metamaskAddress)}
                </p>
              ) : (
                <button onClick={loadUserAddress}>Connect MetaMask</button>
              )}
            </div> */}
            <div className="wallet-btn">
              {tronWalletAddress ? (
                <p
                  className={
                    currentAddress === tronWalletAddress
                      ? "ative-address address-selector"
                      : "address-selector"
                  }
                  onClick={() => {
                    if (!coldownTimeout && currentAddress !== tronWalletAddress) {
                      setCurrentAddress(tronWalletAddress);
                      setColdownTimeout(true);
                    }
                  }}
                >
                  User ID (Tron): {formatString(tronWalletAddress)}
                </p>
              ) : (
                <button onClick={connectTronWallet}>Connect Tron Wallet</button>
              )}
            </div>
            {/* <div className="wallet-btn">
              {phantomWalletId ? (
                <p
                  className={
                    currentAddress === phantomWalletId
                      ? "ative-address address-selector"
                      : "address-selector"
                  }
                  onClick={() => {
                    if (!coldownTimeout && currentAddress !== phantomWalletId) {
                      setCurrentAddress(phantomWalletId);
                      setColdownTimeout(true);
                    }
                  }}
                >
                  User ID (Phantom): {formatString(phantomWalletId)}
                </p>
              ) : (
                <button onClick={connectToWallet}>Connect Phantom</button>
              )}
            </div> */}
            <br />
            <button
              className="btn-submit"
              disabled={!currentAddress}
              onClick={handleSave}
            >
              Submit
            </button>
            <button className="btn-restart" onClick={handleRestart}>
              Restart
            </button>
          </div>
        </div>
      )}
      <div className="wallets-container">
        <div style={{ width: "20px" }}></div>
        {isOpenModal && (
          <div
            className="close-modal"
            onClick={() => setIsOpenModal(false)}
          ></div>
        )}

        <div className="wallets">
          <button className="connect-wallet-btn" onClick={handleOpenModal}>
            {!currentAddress ? "Connect Wallet" : "Wallet"}
          </button>

          {isOpenModal && (
            <>
              <div className="wallets-modal">
                {/* <div className="wallet-btn">
                  {metamaskAddress ? (
                    <p
                      className={
                        currentAddress === metamaskAddress
                          ? "ative-address address-selector"
                          : "address-selector"
                      }
                      onClick={() => {
                        if (
                          !coldownTimeout &&
                          currentAddress !== metamaskAddress
                        ) {
                          setCurrentAddress(metamaskAddress);
                          setColdownTimeout(true);
                        }
                      }}
                    >
                      User ID (Metamask): {formatString(metamaskAddress)}
                    </p>
                  ) : (
                    <button onClick={loadUserAddress}>Connect MetaMask</button>
                  )}
                </div> */}
                
                 <div className="wallet-btn">
                  {tronWalletAddress ? (
                    <p
                      className={
                        currentAddress === tronWalletAddress
                          ? "ative-address address-selector"
                          : "address-selector"
                      }
                      onClick={() => {
                        if (
                          !coldownTimeout &&
                          currentAddress !== tronWalletAddress
                        ) {
                          setCurrentAddress(tronWalletAddress);
                          setColdownTimeout(true);
                        }
                      }}
                    >
                      User ID (Tron): {formatString(tronWalletAddress)}
                    </p>
                  ) : (
                    <button onClick={connectTronWallet}>Connect Tron Wallet</button>
                  )}
                </div>
                {/* <div className="wallet-btn">
                  {phantomWalletId ? (
                    <p
                      className={
                        currentAddress === phantomWalletId
                          ? "ative-address address-selector"
                          : "address-selector"
                      }
                      onClick={() => {
                        if (
                          !coldownTimeout &&
                          currentAddress !== phantomWalletId
                        ) {
                          setCurrentAddress(phantomWalletId);
                          setColdownTimeout(true);
                        }
                      }}
                    >
                      User ID (Phantom): {formatString(phantomWalletId)}
                    </p>
                  ) : (
                    <button onClick={connectToWallet}>Connect Phantom</button>
                  )}
                </div> */}
              </div>
            </>
          )}
        </div>
        <div style={{ width: "20px" }}></div>
      </div>
      <div className="title">
        <img src={logoImg} alt="" />
        <h1>Print Cash - TRON</h1>
      </div>
      <div className="score-area">
        <span>$ {formatNumber(score.toFixed(2))}</span>
      </div>
      <br />
      <br />
      <div className="button-area">
        <button
          className={isPressed ? "btn-size button pressed" : "btn-size button"}
          onClick={() => {
            handleButtonClick();
            handlePress();
          }}
        >
          Click me! <img className="icon-btn" src={clickImg} alt="" />
        </button>
        <button
          className={isPressed3 ? "btn-size button pressed" : "btn-size button"}
          onClick={() => {
            handleUpgradeClick();
            handlePress3();
          }}
          disabled={score < GlobalClickCost}
        >
          Upgrade{" "}
          <div className="up-icon">
            <img className="icon-btn" src={clickImg} alt="" />
            <img className="icon-btn icon-absolute" src={upImg} alt="" />
          </div>
          (-${formatNumber(GlobalClickCost.toFixed(2))})
        </button>
        <button
          className={isPressed2 ? "btn-size button pressed" : "btn-size button"}
          onClick={() => {
            handleBuyMachine();
            handlePress2();
          }}
          disabled={score < GlobalMachineCost}
        >
          Buy Printer <img className="icon-btn" src={printerImg} alt="" /> (-$
          {formatNumber(GlobalMachineCost.toFixed(2))})
        </button>
        <button
          className={isPressed4 ? "btn-size button pressed" : "btn-size button"}
          onClick={() => {
            handleUpgradeMachine();
            handlePress4();
          }}
          disabled={score < GlobalMachineUpCost}
        >
          Upgrade{" "}
          <div className="up-icon">
            <img className="icon-btn" src={printerUpImg} alt="" />
            <img className="icon-btn icon-absolute" src={upImg} alt="" />
          </div>
          (-${formatNumber(GlobalMachineUpCost.toFixed(2))})
        </button>
      </div>
      <div className="info-area">
        <p>Click Value: ${formatNumber(clickValue.toFixed(2))}</p> |
        <p>
          Printers: {machines} @ {formatNumber(pointsPerSecond.toFixed(2))} $/s
        </p>{" "}
        |<p>~ {formatNumber(totalPointsPerSecond.toFixed(2))} $/s </p>
      </div>
      <div className="save-area">
        {saved ? (
          <div className="save-light green"></div>
        ) : (
          <div className="save-light red"></div>
        )}

        <button
          className="btn-stop-save"
          onClick={() => {
            setStart(false);
            setIsOpenModalSubmit(true);
            setFinalScore(score);
          }}
          // disabled={timer < 60}
        >
          Stop and submit
          <img src={iconSave} alt="" />
        </button>
      </div>{" "}
      <div className="time-bar">
        <div className="timer-progress" style={{width: `${(timer/3600)*100}%`,  backgroundColor: `rgb(${(timer/3600)*255}, ${((3600 - timer) / 3600) * 255}, 7)`}}></div>
        <p>{formatTime((timer-3600)*-1)}</p>
      </div>
    </div>
  );
};

export default ClickerGame;
