import logo from './logo.svg';
import './App.css';
import ClickerGame from './pages/ClickerGame';

function App() {
  return (
    <ClickerGame />
  );
}

export default App;
